.summary-pane-header {
  font-size: 1.6rem;
  font-weight: bold;
  height: 4.2rem;
  line-height: 4.2rem;
  padding-left: 2.2rem;
  display: flex;
  justify-content: space-between;
}

.summary-pane-header-right {
  margin-left: -1rem;
  margin-right: 4rem;
  margin-top: -0.5rem;
  cursor: pointer;
}
.summary-pane-header-right .icon-bar-down {
  right: 0;
  margin-top: -2.5rem;
  padding-right: 1rem;
  position: absolute;
}

.summary-pane-header-right .svg-inline--fa {
  vertical-align: top;
  color: #81caff;
}

.close-detail-pane:hover {
  color: var(--interaction-link-color);
}

.summary-pane-content-tab .nav .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--interaction-link-color) !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.summary-pane-content-tab .nav .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  color: var(--interaction-link-color);
}

.summary-pane-content-tab .secondary-label {
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 1.1rem;
}
.summary-pane-container .summary-pane-content-tab a {
  color: var(--unfocused-interaction-link-color);
  font-weight: bold;
  border-color: #e9ecef #e9ecef #dee2e6;
}

#summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
  left: 0;
  padding-left: 2rem;
  display: flex;
  cursor: text;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  padding-top: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 87%;
}
#summary-detail-pane-tabs-tabpane-ActivityLog .activity-log-summary-tab-container {
  left: 0;
  padding-left: 2.5rem;
  cursor: text;
  position: absolute;
  padding-top: 1rem;
  width: 100%;
  overflow-y: auto;
  height: 87%;
}
#summary-detail-pane-tabs-tabpane-Aircraft .summary-aircraft-tab-container {
  left: 0;
  padding-left: 2rem;
  cursor: text;
  position: absolute;
  padding-top: 1rem;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 87%;
}
#summary-detail-pane-tabs-tabpane-Crew .summary-crew-tab-container {
  left: 0;
  padding-left: 0.2rem;
  cursor: text;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 94%;
}

.summary-pane-content-tab .summary-flight-tab-container a {
  color: #81caff;
  text-decoration: none;
  background-color: transparent;
}

.summary-pane-content-tab .flight-tab-title {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.92rem;
}

.activity-log-details .flight-activity-details {
  border-bottom: 0.1rem solid var(--darkModeGray);
}

.activity-log-details .flight-activity-details .activity-log-time {
  padding: 0 5rem 0 5rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.68rem;
  color: #ffffff;
}

.activity-log-details .flight-activity-details .activity-log-date {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.68rem;
  color: #ffffff;
}

.activity-log-details .flight-activity-details .activity-log-actiontag {
  text-align: end;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.68rem;
  color: #808399;
  float: right;
  padding-top: 1rem;
  padding-right: 2.5rem;
}

.activity-log-details .flight-activity-details .activity-log-text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: #ffffff;
}

.activity-log-details .flight-activity-details .activity-log-name {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  color: #ffffff;
}

.new-modal-filters .summary-pane-content-tab .tab-content {
  max-height: calc(100vh - 20.4rem);
}

.legacy-filters .summary-pane-content-tab .tab-content {
  max-height: calc(100vh - 25.5rem);
}

.summary-pane-container .summary-pane-content-tab .nav-tabs {
  height: 4.2rem;
  border-bottom: var(--primary-border-colors);
}

.summary-pane-container .summary-pane-content-tab .nav-tabs .nav-link.active {
  background-color: var(--primary-border-colors);
}

.summary-pane-container .summary-pane-content-tab .nav-tabs .nav-link {
  border: none;
}

.summary-pane-header-left span.pane-header-text-separator {
  border-left: 0.2rem solid #d9d9d9;
  display: inline-block;
  margin: 0 1rem;
  height: 1.3rem;
}
.summary-pane-header .summary-pane-maximize svg{
  color: var(--primary-icon-color);
}

.summary-pane-header-irropscode {
  width: 13.9rem;
  height: 2rem;
  top: 143.6rem;
  left: 36.1rem;
  margin-left: 1rem;
  background-color: #de750c;
  border-radius: 0.4rem;
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.4rem;
}

.section-header {
  display: flex;
}

.section-header-right {
  font-weight: normal;
}
.summary-header-row span.pane-header-text-separator {
  border-left: 0.2rem solid #d9d9d9;
  display: inline-block;
  margin: 0 1rem;
  height: 1.3rem;
  padding-top: 0.8rem;
  margin-top: 0.5rem;
}

@container (max-width: 610px) {
  .nav-link {
    padding: 0rem 0.8rem !important ;
  }
  .nav-tabs {
    margin-left: -1rem;
  }
  .summary-pane-header-left {
    margin-left: -0.2rem;
  }
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
    height: 77%;
  }
  .summary-pane-header-irropscode{
    text-wrap: nowrap;
  }
}

@container (min-width: 610px) and (max-width: 992px) {
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
    height: 73%;
  }
  .summary-pane-maximize-grid{
    text-align: right;
  }
}
@container (min-width: 991px) and (max-width: 1019px) {
  .summary-pane-header-right .icon-bar-down {
    padding-right: 2rem;
  }
  .summary-pane-header-left span.pane-header-text-separator {
    margin: 0 0.2rem;
  }
  .nav-link {
    padding: 0.5rem 0.6rem !important ;
  }
  .summary-pane-header-irropscode {
    margin-left: 0.5rem;
    padding: 0.4rem;
  }
}
@container (min-width: 1019px) and (max-width: 1127px) {
  .summary-pane-header-right .icon-bar-down {
    padding-right: 2rem;
  }
  .summary-pane-header-left span.pane-header-text-separator {
    margin: 0 0.4rem;
  }
  .nav-link {
    padding: 0.5rem 0.6rem !important ;
  }
  .summary-pane-header-irropscode {
    margin-left: 0.5rem;
    padding: 0.4rem;
  }
}
